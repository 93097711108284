import { ready } from "./lib/ready";
import { startStimulusApplication } from "./controllers";

// Import JS files with global side-effects
import "./global_init";
import "jquery-validation";
import "./application_head";
import "./ynab";
import "./analytics";
import "./form_dirty_tracking_all_fields_present";
import "./recurly_subscription_form";
import "./gift_purchases";
import "./group_payment_method";
import "./referral_program";
import "./registrations";
import "./subscription_form_dirty_tracking";
import "./subscriptions";
import "./support_access";
import "./ynab4_upgrade_form";
import "./oauth";
import "./otps";
import "./exit_survey";
import "./form_password_toggle";
import "./kustomer_chat";
import "./confirm_apple_subscription";
import "./impersonation";
import "./invoices";
import "./unlocks";

ready(() => {
    startStimulusApplication();
});
